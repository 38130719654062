<template>
  <div class="oaApplication">
    <div class="home_top"></div>
    <input id="copy_content" type="text" value="" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>
    <div class="oaApplication_cen">
      <div class="oaApplication_o">
        <el-tooltip class="item" effect="light" content="历史记录" placement="top">
          <img @click="open" class="history" src="../../assets/images/oaApplication/his.png" alt="">
        </el-tooltip>
        <div class="oaApplication_content">
          <ul class="tabSwitch">
            <li :class="[{'active': type == (index + 1)}]" @click="handleClick(item.value)" :key="index" v-for="(item,index) in tabList">
              {{item.label}}
            </li>
          </ul>
          <div v-if="type == 1" class="result">
            <div v-if="types == 1" class="result_cen">
              <div class="titles">
                主要技术方案
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[0])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[0] && leftContent[0].content"/>
              <div class="titles">
                非必要技术特征
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[1])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[1] && leftContent[1].content"/>
              <div class="titles">
                不清楚之处
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[2])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[2] && leftContent[2].content"/>
              <div class="titles">
                问题清单
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[3])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[3] && leftContent[3].content"/>
            </div>
            <div v-else class="result_cen">
              <div class="titles">
                主要技术方案
              </div>
              <mavon-editor  :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div class="titles">
                非必要技术特征
              </div>
              <mavon-editor  :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div class="titles">
                不清楚之处
              </div>
              <mavon-editor  :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div class="titles">
                问题清单
              </div>
              <mavon-editor  :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;margin-top: 20px">
              <div  @click="handleStopStep"  style="margin-right: 10px" class="btn">终止</div>
              <div  style="margin-right: 10px" @click="reGenerate" class="btn">重新生成</div>
              <div :style="{cursor: flagw ? 'not-allowed' : 'default'}" class="btn" @click="load({
              title: removeExtension(fileArr.fileName) + '_问题清单',
              chatNumber: chatNumber
            })">下载问题清单</div>
            </div>
          </div>
          <div v-if="type == 2" style="overflow: hidden"  class="result">
            <div v-if="types == 2" class="result_cen">
            <div v-if="sequence == 2">
              <div class="titles">
                主要技术方案
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[0])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[0] && leftContent[0].content"/>
              <div class="titles">
                次要技术方案
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[1])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[1] && leftContent[1].content"/>
              <div class="titles">
                交底书内容概述
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[2])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[2] && leftContent[2].content"/>
            </div>

            <div v-if="sequence == 3">
              <div class="titles">
                对比文件分析
              </div>
              <el-collapse v-if="leftContent[3] && leftContent[3].accordionArr"  class="oa_collapse"  v-model="activeName"  accordion>
                <el-collapse-item :class="[{active:  activeName == index}]" :key="index" v-for="(item,index) in leftContent[3].accordionArr" :name="index">
                  <template slot="title">
                    <div  class="titleHeader active">
                      <p class="title">{{item.title}}</p>
                      <div style="display: flex;align-items: center">
                        <el-tooltip class="item" effect="light" content="删除" placement="top-start">
                          <i @click.stop="del(item)" style="margin-right: 10px;font-size: 16px;font-weight: bold" class="header-icon el-icon-delete"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" content="预览" placement="top-start">
                          <i @click.stop="view(item)" style="margin-right: 10px;font-size: 18px;font-weight: bold" class="header-icon el-icon-view"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" content="查看对比文件分析" placement="top-start">
                          <i @click.stop="openCollapse(index)" style="font-size: 20px;font-weight: bold" :class="['header-icon', {'el-icon-arrow-down': typeof activeName == 'string' || activeName != index }, {'el-icon-arrow-up': activeName == index}]"></i>
                        </el-tooltip>
                      </div>
                    </div>
                    <ul class="titleHeader">
                      <li style="margin-bottom: 8px">
                        <span style="font-weight: bold">申请号:</span>{{item.applyNoAll}}
                      </li>
                      <li style="margin-bottom: 8px">
                        <span style="font-weight: bold">公开号:</span>{{item.pubNoAll}}
                      </li>
                    </ul>
                    <ul v-if="typeof activeName == 'string' || activeName != index" class="titleHeader">
                      <li style="margin-bottom: 8px">
                        <!--                        {{item.abstractInfo}}-->
                        <span style="font-weight: bold">摘要:</span>{{item.abstractInfo}}
                      </li>
                    </ul>
                  </template>
                  <ul>
                    <li style="margin-bottom: 8px">
                      <span style="font-weight: bold">摘要:</span>{{item.abstractInfo}}
                    </li>
                    <li style="margin-bottom: 8px">
                      <span style="font-weight: bold">对比分析:</span><br/><mavon-editor @change="(e) => contentChange(e, item)" :placeholder="' '"
                                                                                     style="min-height: 150px;box-shadow: none;z-index: 500;margin-top: 10px" :config="editorConfig"
                                                                                     :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                                                                                     :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                                                                                     v-model="item.differenceContent"/>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
              <mavon-editor v-else @change="(e) => contentChange(e, leftContent[100])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[100] && leftContent[100].content"/>

            </div>
            <div v-if="sequence == 70">
              <div class="titles">
                关键词/ 检索式
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[4])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[4] && leftContent[4].content"/>
            </div>
            <div v-if="sequence == 80">
              <div class="titles">
                IPC分类
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[5])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[5] && leftContent[5].content"/>
            </div>
          </div>
            <div v-else class="result_cen">
              <div v-if="sequence == 2">
                <div class="titles">
                  主要技术方案
                </div>
                <mavon-editor  :placeholder="' '"
                              style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                              :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                              :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
                <div class="titles">
                  次要技术方案
                </div>
                <mavon-editor  :placeholder="' '"
                              style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                              :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                              :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
                <div class="titles">
                  交底书内容概述
                </div>
                <mavon-editor :placeholder="' '"
                              style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                              :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                              :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              </div>

              <div v-if="sequence == 3">
                <div class="titles">
                  对比文件分析
                </div>
                <el-collapse v-if="leftContent[3] && leftContent[3].accordionArr"  class="oa_collapse"  v-model="activeName"  accordion>
                  <el-collapse-item :class="[{active:  activeName == index}]" :key="index" v-for="(item,index) in leftContent[3].accordionArr" :name="index">
                    <template slot="title">
                      <div  class="titleHeader active">
                        <p class="title">{{item.title}}</p>
                        <div style="display: flex;align-items: center">
                          <el-tooltip class="item" effect="light" content="删除" placement="top-start">
                            <i @click.stop="del(item)" style="margin-right: 10px;font-size: 16px;font-weight: bold" class="header-icon el-icon-delete"></i>
                          </el-tooltip>
                          <el-tooltip class="item" effect="light" content="预览" placement="top-start">
                            <i @click.stop="view(item)" style="margin-right: 10px;font-size: 18px;font-weight: bold" class="header-icon el-icon-view"></i>
                          </el-tooltip>
                          <el-tooltip class="item" effect="light" content="查看对比文件分析" placement="top-start">
                            <i @click.stop="openCollapse(index)" style="font-size: 20px;font-weight: bold" :class="['header-icon', {'el-icon-arrow-down': typeof activeName == 'string' || activeName != index }, {'el-icon-arrow-up': activeName == index}]"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <ul class="titleHeader">
                        <li style="margin-bottom: 8px">
                          <span style="font-weight: bold">申请号:</span>{{item.applyNoAll}}
                        </li>
                        <li style="margin-bottom: 8px">
                          <span style="font-weight: bold">公开号:</span>{{item.pubNoAll}}
                        </li>
                      </ul>
                      <ul v-if="typeof activeName == 'string' || activeName != index" class="titleHeader">
                        <li style="margin-bottom: 8px">
                          <!--                        {{item.abstractInfo}}-->
                          <span style="font-weight: bold">摘要:</span>{{item.abstractInfo}}
                        </li>
                      </ul>
                    </template>
                    <ul>
                      <li style="margin-bottom: 8px">
                        <span style="font-weight: bold">摘要:</span>{{item.abstractInfo}}
                      </li>
                      <li style="margin-bottom: 8px">
                        <span style="font-weight: bold">对比分析:</span><br/><mavon-editor :placeholder="' '"
                                                                                       style="min-height: 150px;box-shadow: none;z-index: 500;margin-top: 10px" :config="editorConfig"
                                                                                       :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                                                                                       :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
                      </li>
                    </ul>
                  </el-collapse-item>
                </el-collapse>
                <mavon-editor v-else :placeholder="' '"
                              style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                              :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                              :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>

              </div>
              <div v-if="sequence == 70">
                <div class="titles">
                  关键词/ 检索式
                </div>
                <mavon-editor :placeholder="' '"
                              style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                              :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                              :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              </div>
              <div v-if="sequence == 80">
                <div class="titles">
                  IPC分类
                </div>
                <mavon-editor :placeholder="' '"
                              style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                              :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                              :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;margin-top: 20px">
              <div  @click="handleStopStep"  style="margin-right: 10px"  class="btn">终止</div>
              <div   v-if="sequence == 2" style="margin-right: 10px" @click="reGenerate" class="btn">重新生成</div>
              <div v-else style="margin-right: 10px"  @click="refresh(messageData.filter(item => item.item == sequence)[0],messageData.findIndex(value => value.item == sequence))" class="btn">重新生成</div>

              <div v-if="sequence ==2" class="btn" :style="{cursor: flagw ? 'not-allowed' : 'default'}" @click="step(3, sequence >= 2)">对比文件分析</div>
              <div v-if="sequence ==3" class="btn" :style="{cursor: flagw ? 'not-allowed' : 'default'}" @click="step(70, sequence >= 3)">关键词/检索式</div>
              <div v-if="sequence ==70" class="btn" :style="{cursor: flagw ? 'not-allowed' : 'default'}" @click="step(80, sequence >= 70)">IPC分类</div>
              <div v-if="sequence ==80" class="btn" :style="{cursor: flagw ? 'not-allowed' : 'default'}" @click="load({
              title: removeExtension(fileArr.fileName) + '_检索报告',
              chatNumber: chatNumber
            })">下载检索报告</div>
            </div>
          </div>
          <div v-if="type == 3" class="result">
            <div v-if="types == 3">
              <div style="font-size: 14px" class="titles">
                说明书摘要
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[0])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[0] && leftContent[0].content"/>
              <div style="font-size: 14px" class="titles">
                权利要求书
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[1])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[1] && leftContent[1].content"/>
              <div style="font-size: 14px" class="titles">
                说明书
              </div>
              <div class="titles">
                发明名称
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[2])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[2] && leftContent[2].content"/>
              <div class="titles">
                技术领域
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[3])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[3] && leftContent[3].content"/>
              <div class="titles">
                背景技术
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[4])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[4] && leftContent[4].content"/>
              <div class="titles">
                发明内容
              </div>
              <mavon-editor @change="(e) => contentChange(e, leftContent[5])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[5] && leftContent[5].content"/>
              <div class="titles">
                具体实施方式
              </div>
              <mavon-editor v-if="leftContent[6] && leftContent[6].content" @change="(e) => contentChange(e, leftContent[6])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[6] && leftContent[6].content"/>
              <mavon-editor v-if="leftContent[7] && leftContent[7].content" @change="(e) => contentChange(e, leftContent[7])" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            v-model="leftContent[7] && leftContent[7].content"/>
              <mavon-editor v-if="!(leftContent[6] && leftContent[6].content) && !(leftContent[7] && leftContent[7].content)" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
              />
            </div>
            <div v-else>
              <div style="font-size: 14px" class="titles">
                说明书摘要
              </div>
              <mavon-editor :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div style="font-size: 14px" class="titles">
                权利要求书
              </div>
              <mavon-editor :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div style="font-size: 14px" class="titles">
                说明书
              </div>
              <div class="titles">
                发明名称
              </div>
              <mavon-editor :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
                            />
              <div class="titles">
                技术领域
              </div>
              <mavon-editor :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div class="titles">
                背景技术
              </div>
              <mavon-editor :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div class="titles">
                发明内容
              </div>
              <mavon-editor  :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <div class="titles">
                具体实施方式
              </div>
              <mavon-editor v-if="leftContent[6] && leftContent[6].content" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <mavon-editor v-if="leftContent[7] && leftContent[7].content" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"/>
              <mavon-editor v-if="!(leftContent[6] && leftContent[6].content) && !(leftContent[7] && leftContent[7].content)" :placeholder="' '"
                            style="min-height: 150px;box-shadow: none;z-index: 500" :config="editorConfig"
                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false" :default-preview="false"
                            :toolbars="markdownOption" class="mavonEditor_1" language="zh-CN" ref="md"
              />
            </div>
            <el-tooltip class="item" effect="light" content="下载" placement="top">
              <img :style="{cursor: flagw ? 'not-allowed' : 'default'}" @click="load({
              title: removeExtension(fileArr.fileName) + '_新申请',
              chatNumber: chatNumber
            })" class="load" src="../../assets/images/oaApplication/download.png" alt="">
            </el-tooltip>

          </div>
          <div class="chat">
            <div  class="upload">
              <div  class="upload_item_1" style="display: flex">
                <div style="display: flex;align-items: center">
                  <div style="margin-right: 15px;width:80px;font-size: 14px">
                    技术交底书
                  </div>
                  <el-upload
                    :on-error="errorEvent"
                    :headers="headers"
                    :show-file-list="false"
                    class="upload-demo"
                    action="/aigc/app/file/uploadToMoonshot?fileType=4"
                    :on-success="uploadFn"
                    name="file">
                    <div v-if="!fileArr" class="uploadFile"><i style="font-size: 18px" class="el-icon-upload2"></i> 技术交底书上传
                    </div>
                    <div v-else class="uploadFiled"><i style="font-size: 18px" class="el-icon-document"></i>
                      <p>{{fileArr.fileName}}</p> <i class="el-icon-close"></i></div>
                  </el-upload>
                </div>

                <div @click="generate" class="btn">
                  立即生成
                </div>
              </div>
              <div v-if="rotation" class="upload_item_2">
                <div style="display: flex;align-items: center" v-if="type == 2">
                  <div style="margin-right: 15px;width: 80px;text-align: left;font-size: 14px">
                    对比文件
                  </div>
                  <el-upload
                    style="width: 75%;"
                    :on-error="errorEvent"
                    :on-exceed="handleExceed"
                    :headers="headers"
                    class="upload-demo"
                    :file-list="fileLists"
                    action="/aigc/app/file/uploadToMoonshot?fileType=5"
                    :on-remove="handleRemoves"
                    :on-success="uploadFns"
                    :limit="5"
                  >
                    <!--                  @click="comparativeFilesDialog = true"-->
                    <div  class="uploadFile"><i style="font-size: 18px" class="el-icon-upload2"></i> 上传对比文件</div>
                  </el-upload>
                </div>
                <div style="display: flex;align-items: center" v-if="type == 3">
                  <div style="margin-right: 15px;width: 80px;text-align: left;font-size: 14px">
                    专利领域
                  </div>
                  <el-select  class="select" v-model="value" placeholder="请选择专利领域">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div v-if="type != 1" class="rotation">
                <i @click="rotation = !rotation" style="font-size: 18px" :class="[{'el-icon-arrow-down': !rotation},{'el-icon-arrow-up': rotation}]"></i>
              </div>
            </div>
            <div class="chat_content">
              <div id="chatBox" class="chatBox">
                <div v-if="types == type">
                  <div :id="'id'+ item.item" class="chatBox_cen" :key="index" v-for="(item,index) in messageData">
                    <div v-if="item.show">
                      <div v-if="item.disabled" class="chat_left">
                        <div class="content left">
                          {{item?.answer}}
                        </div>
                        <img src="../../assets/images/oaApplication/ren.png" alt="">
                      </div>
                      <div class="chat_right">
                        <img src="../../assets/images/oaApplication/hui.png" alt="">
                        <div class="content right">
                          <mavon-editor v-if="item.item != 3" @change="(e) => contentChange(e, item)" :placeholder="' '"
                                        style="min-height: 30px;box-shadow: none;z-index: 500;background: #ffffff" :config="editorConfig"
                                        :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false"
                                        :default-preview="false" :toolbars="markdownOption" class="mavonEditor_2"
                                        language="zh-CN" ref="md" v-model="item.content"/>
                          <el-collapse @change="collapseChange" class="collapse" v-else v-model="activeNames"  accordion>
                            <el-collapse-item :key="index" v-for="(item,index) in item.accordionArr" :name="index">
                              <template slot="title">
                                <div style="display: flex;justify-content: space-between;width: 90%;align-items: center">
                                  <el-tooltip class="item" effect="light" :content="item.title" placement="top">
                                    <p class="title">{{item.title}}</p>
                                  </el-tooltip>
                                  <div style="display: flex;align-items: center">
                                    <el-tooltip class="item" effect="light" content="删除" placement="top-start">
                                      <i @click.stop="del(item,index)" style="margin-right: 10px" class="header-icon el-icon-delete"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="light" content="预览" placement="top-start">
                                      <i @click.stop="view(item)" class="header-icon el-icon-view"></i>
                                    </el-tooltip>
                                  </div>
                                </div>
                              </template>
                              <ul>
                                <li style="margin-bottom: 8px">
                                  <span style="font-weight: bold">申请号:</span>{{item.applyNoAll}}
                                </li>
                                <li style="margin-bottom: 8px">
                                  <span style="font-weight: bold">公开号:</span>{{item.pubNoAll}}
                                </li>
                                <li style="margin-bottom: 8px">
                                  <span style="font-weight: bold">摘要:</span>{{item.abstractInfo}}
                                </li>
                                <li style="margin-bottom: 8px">
                                  <span style="font-weight: bold">对比分析:</span><mavon-editor v-if="item.item != 3" @change="(e) => contentChange(e, item)" :placeholder="' '"
                                                                                            style="min-height: 30px;box-shadow: none;z-index: 500;background: #ffffff" :config="editorConfig"
                                                                                            :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false"
                                                                                            :default-preview="false" :toolbars="markdownOption" class="mavonEditor_2"
                                                                                            language="zh-CN" ref="md" v-model="item.differenceContent"/>
                                </li>
                              </ul>
                            </el-collapse-item>
                          </el-collapse>
                          <div class="operator">

                            <el-tooltip class="item" effect="light" content="复制" placement="top">
                              <img @click="copy(item.content)" v-if="item.operator?.includes('copy')"
                                   src="../../assets/images/oaApplication/copy.png" alt="">
                            </el-tooltip>
                            <el-tooltip class="item" effect="light" content="粘贴到对应的位置" placement="top">
                              <img @click="exchange(item)" v-if="item.operator?.includes('exchange')"
                                   src="../../assets/images/oaApplication/exchange.png" alt="">
                            </el-tooltip>
                            <el-tooltip class="item" effect="light" content="重新回答" placement="top">
                              <img @click="refresh(item,index)" v-if="item.operator?.includes('refresh')"
                                   src="../../assets/images/oaApplication/refresh.png" alt="">
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="messageData.filter(item => item.show).length > 0 && flagws" class="myChatStopGenerate_box">
                    <el-button class="myChatStopGenerate"  @click="handleStopStep">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class=" iconify iconify--ri" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16M9 9h6v6H9z"/>
                      </svg>
                      <span>终止生成</span>
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="enter">
                <div class="input_">
                  <el-input
                    :disabled="flagw"
                    @keyup.enter.native="send"
                    placeholder="请输入内容"
                    v-model="textarea"/>
                  <img  @click="send" src="../../assets/images/oaApplication/feiji.png" alt="">
                </div>
                <el-popover
                  v-if="type == 3"
                  popper-class="enter_hover"
                  placement="top"
                  trigger="hover">
                  <ul>
                    <li :class="[{disabled: sequence >= 0}]"  @click="step(120, sequence >= 0)">{{claimsFlag ? '查看权利要求书' : '权利要求书'}}</li>
                    <li :class="[{disabled: sequence >= 120}]" @click="step(125, sequence >= 120)">发明摘要</li>
                    <li :class="[{disabled: sequence >= 125}]" @click="step(130, sequence >= 125)">发明名称</li>
                    <li :class="[{disabled: sequence >= 130}]" @click="step(140, sequence >= 130)">技术领域</li>
                    <li :class="[{disabled: sequence >= 140}]" @click="step(150, sequence >= 140)">背景技术</li>
                    <li :class="[{disabled: sequence >= 150}]" @click="step(160, sequence >= 150)">发明内容</li>
                    <li :class="[{disabled: sequence >= 160}]" @click="step('', sequence >= 160)">具体实施方式</li>
                  </ul>
                  <div class="generated" slot="reference">
                    生成
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      class="oa_drawer"
      size="400px"
      :show-close="false"
      :modal="false"
      title=""
      :visible.sync="drawer"
      :direction="direction">
      <div slot="title">
        <div class="header">
          <div class="title">
            <img src="../../assets/images/oaApplication/historyList.png" alt=""> 历史记录
          </div>
          <img @click="drawer = false" style="cursor: pointer" src="../../assets/images/oaApplication/close.png" alt="">
        </div>
      </div>
      <el-table
        :data="historyArr"
        style="width: 100%"
        height="620">
        <el-table-column
          fixed
          show-overflow-tooltip
          label="文件名称">
          <template slot-scope="scope">
            {{scope.row.title}}_申请文件
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="生成时间">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="60">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="loadId(scope.row)"
              type="text"
              size="small">
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="pageConfig.pageSize"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </el-drawer>
    <!--    上传对比文件-->
    <el-dialog custom-class="comparativeFilesDialog" center :close-on-click-modal="false" :close-on-press-escape="false" width="500px" title="对比文件上传"
               :visible.sync="comparativeFilesDialog">
      <el-upload
        :on-error="errorEvent"
        :on-exceed="handleExceed"
        :headers="headers"
        class="upload-demo"
        :file-list="fileList"
        drag
        action="/aigc/app/file/uploadToMoonshot?fileType=5"
        :on-remove="handleRemoves"
        :on-success="uploadFns"
        :limit="5"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--        <el-button @click="comparativeFilesDialog = false" type="primary">关闭</el-button>-->
      <!--      </span>-->
    </el-dialog>

    <el-dialog custom-class="uploadFiles" center :close-on-click-modal="false" :close-on-press-escape="false" width="500px" title="上传文件"
               :visible.sync="uploadFiles">
      <el-tabs type="border-card">
        <el-tab-pane label="附图文件">
          <el-upload
            :headers="headers"
            :on-error="errorEvent"
            class="upload-demo"
            :file-list="fufileList"
            :on-success="fuuploadFn"
            drag
            :data="{
              fileType: 6,
               chatNumber: chatNumber
            }"
            action="/aigc/app/file/uploadForNewApp"
            :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-tab-pane>
        <el-tab-pane label="实施例文件">
          <el-upload
            :headers="headers"
            :on-error="errorEvent"
            class="upload-demo"
            :file-list="shifileList"
            :on-success="shiuploadFn"
            drag
            :data="{
              fileType: 7,
               chatNumber: chatNumber
            }"
            action="/aigc/app/file/uploadForNewApp"
            :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="proceed()" type="primary">继续</el-button>
      </span>
    </el-dialog>
    <el-dialog custom-class="dialogviewFileVisible" width="800px" center title="文件预览" :visible.sync="dialogviewFileVisible">
      <mavon-editor @change="(e) => contentChange(e, item)" :placeholder="' '"
                    style="max-height: 500px;box-shadow: none;z-index: 500;background: #ffffff" :config="editorConfig"
                    :externalLink="externalLink" defaultOpen="edit" :toolbarsFlag="false"
                    :default-preview="false" :toolbars="markdownOption" class="mavonEditor_2"
                    language="zh-CN" ref="md" v-model="viewFile"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogviewFileVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import store from "../../store";

  const axios = require('axios');
  axios.defaults.timeout = 60 * 1000 * 5;
  import hljsCss from "mavon-editor/src/lib/core/hljs/lang.hljs.css";
  import {Message} from "element-ui";

  export default {
    name: "index",
    data() {
      return {
        stopSignal: null,  // 保存取消令牌
        recordStepsCode: null, //记录执行步骤
        flagw: false,
        flagws: false,
        recordSteps: {},
        rotation: true,
        copyOrder: '',
        comparativeFilesDialog: false,
        activeName: 0,
        activeNames: 0,
        comparativeFlag: 0,
        dialogviewFileVisible: false,
        viewFile: '',
        accordionArr: [],
        fileList: [],
        prompt: '',
        sequence: 0,
        type: 1,
        types: 1,
        tabList: [
          {
            label: '交底书解读',
            value: 1
          },
          {
            label: '检索报告',
            value: 2
          },
          {
            label: '新申请撰写',
            value: 3
          }
        ],
        leftContent: [
          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },          {
            content: ''
          },
        ],
        fufileList: [],
        shifileList: [],
        uploadFiles: false,
        refreshFlag: false,
        order: 0,
        //抽屉
        pageConfig: {
          pageNum: 1,
          pageSize: 10
        },
        total: 0,
        drawer: false,
        direction: 'rtl',
        historyArr: [],
        chatArr: [],
        markdownOption: { //自定义文本标签
          bold: true, // 粗体
          italic: true,// 斜体
          strikethrough: true, // 中划线
          underline: true, // 下划线
        },
        externalLink: {
          markdown_css: function () {
            return '';
          },
          hljs_js: function () {
            return '';
          },
          hljs_lang: function (lang) {
            return '';
          },
          hljs_css: function (css) {
            if (hljsCss[css]) {
              return '';
            }
            return '';
          },
          katex_js: function () {
            return '';
          },
          katex_css: function () {
            return '';
          }
        },
        editorConfig: {
          toolbar: {
            githubLink: false, // 禁用 GitHub 链接
          },
        },
        flag10: false,
        flag20: false,
        fileArr: '',
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
        options: [
          {
            value: 1,
            label: '化学'
          }, {
            value: 2,
            label: '机械'
          }, {
            value: 3,
            label: '电学'
          }
        ],
        value: '',
        textarea: '',
        round: null,
        messageData: [],
        chatNumber: ''
      };
    },
    computed: {
      claimsFlag(){
        let arr = this.messageData.filter(item => item.item ==  121)
        return arr.length > 0 ? true : false
      },
      fileLists(){
        let list = JSON.parse(JSON.stringify(this.fileList))
        return  list.map((item,index) => {
          item.name = `对比文件${index +1}:  ${item.fileName}`
          return item
        })
      },
      disableLoad(){
        if(this.types == 1) {
          return this.leftContent[0].content && this.leftContent[1].content && this.leftContent[2].content && this.leftContent[3].content && !this.flagw
        }else if(this.types == 2) {
          return this.leftContent[0].content && this.leftContent[1].content && this.leftContent[2].content && this.leftContent[3].content && this.leftContent[4].content && this.leftContent[5].content && !this.flagw
        }else if(this.types == 3) {
          return this.leftContent[0].content && this.leftContent[1].content && this.leftContent[2].content && this.leftContent[3].content && this.leftContent[4].content && this.leftContent[5].content  && (this.leftContent[6].content || this.leftContent[7].content) && !this.flagw
        }else {
          return false
        }
      }
    },
    watch: {
      flag10(newVal) {
        if (this.flag10 && this.flag20) {
          this.messageEvent([100])
          this.flag10 = false
          this.flag20 = false
        }
      },
      flag20(newVal) {
        if (this.flag10 && this.flag20) {
          this.messageEvent([100])
          this.flag10 = false
          this.flag20 = false
        }
      },
      fileList: {
        handler(newVal, oldVal) {
          this.$nextTick(() => {
            // 这个回调将在 DOM 更新完成后执行
            this.jisuan()
          });
        },
        deep: true,  // 深度监听
        immediate: true
      },
      rotation() {
        this.$nextTick(() => {
          // 这个回调将在 DOM 更新完成后执行
          this.jisuan()
        });
      }
    },
    mounted() {
      this.history()
      this.$nextTick(() => {
        // 这个回调将在 DOM 更新完成后执行
        this.jisuan()
      });
      setTimeout(() => {
        this.$nextTick(() => {
          try {
            const elements = document.querySelector('.result');
            elements?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
          }catch (e) {

          }
          try {
            const element = document.querySelector('.result_cen');
            element?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
          }catch (e) {

          }
        })
      })
    },
    methods: {
      handleStopStep(){
        // 在需要取消请求时调用 cancel() 方法
        if (this.stopSignal) {
          this.stopSignal.cancel("请求已被取消");
        }
      },
      handleStopSteps(){
        // 在需要取消请求时调用 cancel() 方法
        if (this.stopSignal2) {
          this.stopSignal2.cancel("请求已被取消");
        }
      },
      errorEvent(err) {
        this.$message.error(err);
      },
      collapseChange() {

      },
      openCollapse(index) {
        if(this.activeName == index) {
          this.activeName = 100000
        }else {
          this.activeName = index
        }
      },
      jisuan(){
        const element = document.querySelector('.upload');
        const height = element.scrollHeight + 20;
        //chat_content
        const elements = document.querySelector('.chat_content');
        elements.style.height = `calc(100% - ${height}px)`;  // 设置高度为视口高度减去50px
      },
      unbindFiles(items,indexs){
        this.$axios.delete('/aigc/app/new/message/'+ this.chatNumber +'/' + items.difference).then(({data}) => {
          if (data.code == 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.leftContent[3].accordionArr.splice(indexs, 1);
            // this.$set(, index, {...this.messageData[this.messageData.findIndex(value => value.item == 3)],accordionArr: this.messageData[this.messageData.findIndex(value => value.item == 3)].accordionArr.filter(item => item.difference != items.difference)});
          }
        })
      },
      del(item,index){
        this.$axios.post('/aigc/app/new/unbindFiles',{
          caseNumber: this.round,
          chatNumber: this.chatNumber,
          fileIds: item.fileId,
          fileType: item.fileType,
        }).then(({data}) => {
          if (data.code == 0) {
            this.unbindFiles(item,index)
          }
        })
      },
      view(item){
        this.$axios.get('/aigc/app/file/' + item.fileId).then(({data}) => {
          if (data.code == 0) {
            this.dialogviewFileVisible = true
            this.viewFile = JSON.parse(data.data.fileContent).content
          }
        })
      },
      searchSimilarPatents() {
        this.$axios.get('/aigc/app/new/searchSimilarPatents/' + this.round + '?chatNumber=' + this.chatNumber).then(({data}) => {
          if (data.code == 0) {
            this.getSimilarPatents()
          }
        })
      },
      getSimilarPatents() {
        this.$axios.get('/aigc/app/new/getSimilarPatents/' + this.round + '?chatNumber=' + this.chatNumber).then(({data}) => {
          if (data.code == 0) {
            this.accordionArr = data.data
            this.comparativeFlag = 0
            this.messageEventList(this.accordionArr[0].difference, 0)
          }
        })
      },
      resetHandleClick(){

      },
      handleClick(val) {
        setTimeout(() => {
          this.$nextTick(() => {
            try {
              const elements = document.querySelector('.result');
              elements?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
            }catch (e) {

            }
            try {
              const element = document.querySelector('.result_cen');
              element?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
            }catch (e) {

            }
          })
        })


        this.jisuan()
        // this.rotation = true
        // this.copyOrder = ''
        // this.activeName = 0
        // this.activeNames = 0
        // this.comparativeFlag = 0
        // this.viewFile = ''
        // this.accordionArr = []
        // this.fileList = []
        this.type = val
        //
        // this.fufileList = [];
        // this.shifileList = [];
        // this.uploadFiles = false;
        // this.refreshFlag = false;
        // this.order = 0;
        // this.prompt = '';
        if(this.type == 2) {
          this.sequence = 2;
        }else {
          this.sequence = 0;
        }

        // this.flag10 = false;
        // this.flag20 = false;
        // // this.fileArr = '';
        // this.value = '';
        // this.textarea = '';
        // this.round = null;
        // this.messageData = [];
        // this.chatNumber = ''
      },
      bianjiqi_init(){
        // 假设编辑器容器为 .markdown-editor
        const elements = document.querySelectorAll('.mavonEditor_2');
        // 调整编辑器的高度
        for (let i = 0; i < elements.length; i++){
          editorContainer[i].style.height = 'auto';
        }
      },
      //附图上传
      fuuploadFn(file, fileList) {
        console.log(file, fileList,"111111")
        if (file.code == 0) {
          this.fufileList = [fileList]
          this.$message({
            message: '文件上传成功',
            type: 'success'
          });
        } else {
          this.fufileList = []
          this.$message.error(file.msg);
        }
        console.log(file, fileList,"222222")
      },
      shiuploadFn(file, fileList) {
        if (file.code == 0) {
          this.shifileList = [fileList]
          this.$message({
            message: '文件上传成功',
            type: 'success'
          });
        } else {
          this.shifileList = []
          this.$message.error(file.msg);
        }
      },
      handleRemoves(file, fileList) {
        this.fileList = fileList
      },
      contentChange(e, item) {
        return;
        this.saveMessage({...item,content: e})
      },
      copy(text) {

        // 使用 Clipboard API 复制纯文本 Markdown
        navigator.clipboard.writeText(text).then(() => {
          this.$message('已复制');
        }).catch(err => {
          this.$message('复制失败');
        });
        return;
        if (text) {
          //获取要赋值的input的元素
          var inputElement = document.getElementById("copy_content");
          //给input框赋值
          inputElement.value = text;
          //选中input框的内容
          inputElement.select();
          // 执行浏览器复制命令
          document.execCommand("Copy");
          //提示已复制
          this.$message('已复制');
        } else {
          //提示已复制
          this.$message('暂无数据');
        }
      },
      exchange(item) {
        item = JSON.parse(JSON.stringify(item))
        if(this.type == 3) {
          switch (Number(item.item)) {
            //说明书摘要
            case 125:
              this.$set(this.leftContent, 0, item);
              break;
            //说明书
            case 121:
              this.$set(this.leftContent, 1, item);
              break;
            //发明名称
            case 130:
              this.$set(this.leftContent, 2, item);
              break;
            //技术领域
            case 140:
              this.$set(this.leftContent, 3, item);
              break;
            //背景技术
            case 150:
              this.$set(this.leftContent, 4, item);
              break;
            //发明内容
            case 160:
              this.$set(this.leftContent, 5, item);
              break;
            //具体实施方式
            case 170:
              this.$set(this.leftContent, 6, item);
              break;
            case 190:
              this.$set(this.leftContent, 6, item);
              break;
            case 210:
              this.$set(this.leftContent, 6, item);
              break;
            case 180:
              this.$set(this.leftContent, 7, item);
              break;
            case 200:
              this.$set(this.leftContent, 7, item);
              break;
            case 220:
              this.$set(this.leftContent, 7, item);
              break;
          }
          // this.contentChange(item.content, item)
        }

      },
      refresh(item,index) {
        this.refreshFlag = true
        this.handleStopStep()
        if(this.type == 2 && item.item == 3){
          this.getSimilarPatents()
          return;
        }

        if(item.item == 0){
          store.commit("loadingBool", true);
          this.$axios.post(`/aigc/app/new/message`, {
            "chatNumber": this.chatNumber,
            "caseNumber": this.round,
            "chatType": 0,
            "prompt": item.prompt
          }).then(({data}) => {
            store.commit("loadingBool", false);
            if (data.code == 0) {
              this.completions(data.data, 0,index)
            }
          }).catch(() => {
            store.commit("loadingBool", false);
          })
        }else {
          this.messageEventList(item.item,index)
        }
      },
      deleteRow(index, rows) {
        rows.splice(index, 1);
      },
      open() {
        this.drawer = true
      },
      handleClose(done) {
        done();
      },
      handleCurrentChange(e) {
        this.pageConfig.pageNum = e
        this.history()
      },
      geshi(item) {
        const isoString = item;

// 创建 Date 对象
        const date = new Date(isoString);

// 获取 UTC 年、月、日、时、分、秒
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');  // 月份从 0 开始，需要加 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

// 拼接为 "YYYY-MM-DD HH:mm:ss" 格式
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDate;

      },
      history() {
        this.$axios.get('/aigc/app/new/content/page',{
          params: {
            current: this.pageConfig.pageNum,
            size: this.pageConfig.pageSize
          }
        }).then(({data}) => {
          if (data.code == 0) {
            this.historyArr = data.data.records.map(item => {
              item.createTime = this.geshi(item.createTime)
              return item
            })
            this.total = data.data.total
          }
        })
      },
      send() {
        if(this.flagw) {
          return;
        }
        if (this.fileArr) {
          this.refreshFlag = false
          store.commit("loadingBool", true);
          this.prompt = ''
          this.prompt = this.textarea
          this.textarea = ''
          this.$axios.post(`/aigc/app/new/message`, {
            "chatNumber": this.chatNumber,
            "caseNumber": this.round,
            "chatType": 0,
            "prompt": this.prompt
          }).then(({data}) => {
            store.commit("loadingBool", false);
            if (data.code == 0) {
              this.completions(data.data, 0)
            }
          }).catch(() => {
            store.commit("loadingBool", false);
          })
        } else {
          this.$message.error('请先上传生成所需的文件');
        }
      },
      proceed() {
        this.uploadFiles = false
        if (this.value == 1) {
          //化学
          this.messageEventList(170)
        } else if (this.value == 2) {
          //机械
          this.messageEventList(210)
        } else if (this.value == 3) {
          //电学
          this.messageEventList(190)
        }
        this.sequence = 170
      },
      step(val,panduan) {
        console.log(this.leftContent,"this.leftContent")
        if((val == 3 || val == 70 || val == 80) && this.flagw) {
          return;
        }

        if(val == 3 && !(this.leftContent[0].content && this.leftContent[1].content && this.leftContent[2].content) ) {
            return;
        }

        let arr = []
        if(val) {
          arr = this.messageData.filter(item => item.item == (val == 120 ? 121 : val))
        }else {
          arr = this.messageData.filter(item => item.item == 170 || item.item == 190 || item.item == 220)
        }
        if(arr.length > 0) {
          const targetElement = document.getElementById('id' + arr[0].item);
          targetElement.scrollIntoView({
            behavior: 'smooth', // 平滑滚动
            block: 'start'      // 将元素顶部对齐到视口顶部
          });
        }else {
          if(panduan){
            this.refreshFlag = false
            if (val) {
              if(this.type == 3) {
                this.sequence = val
                this.messageEventList(val)
              }else if(this.type == 2) {
                this.sequence = val
                if(val == 3) {
                  this.copyOrder = this.order
                  this.getSimilarPatents()
                }else {
                  this.messageEventList(val)
                }
              }
            } else {
              this.uploadFiles = true
            }
          }
        }

      },
      saveMessage(obj) {
        return;
        this.$axios.put("/aigc/app/new/message", obj).then(({data}) => {

        })
      },
      scrollToBottom() {
        const scrollableDiv = document.getElementById('chatBox');
        scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      },
      completions(value, items, index) {
        this.recordSteps = {
          value, items, index
        }
        if ((this.type == 3 && items == 10) || (this.type == 3 && items == 20) || (this.type == 3 && items == 120) || (this.type == 3 && items == 210)) {
          store.commit("loadingBool", true);
          this.$axios.get("/aigc/app/new/completions?conversationId=" + value).then(({data}) => {
            if (items == 10) {
              this.flag10 = true
            } else if (items == 20) {
              this.flag20 = true
            } else if (items == 120) {
              this.messageEventList(121)
            } else if (items == 210) {
              this.messageEventList(220)
            }
          }).catch(err => {
            store.commit("loadingBool", false);
          })
        } else {
          store.commit("loadingBool", true);
          this.recordStepsCode = {
            value, items, index
          }
          // 创建一个新的 CancelToken 实例
          this.stopSignal = axios.CancelToken.source();
          this.$axios({
            method: 'GET',
            responseType: 'stream',
            cancelToken: this.stopSignal.token, // 将 cancelToken 放在配置中
            url: "/aigc/app/new/completions?conversationId=" + value,
            onDownloadProgress: ({target}) => {
              this.flagw = true
              this.flagws = true
              let response = target.response
              let itemList = ""
              let item = ""
              if (!response.endsWith("}")) {
                itemList = response.split("{")
                item = "{" + itemList[itemList.length - 2]
              } else {
                itemList = response.split("{")
                item = "{" + itemList[itemList.length - 1]
              }

              if(JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")) {
                // this.bianjiqi_init()
                store.commit("loadingBool", false);
              }

              if (items == 10) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.leftContent, 0, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成主要技术方案',
                    operator:  ['refresh', 'exchange','zhongzhi']
                  });
                  this.$set(this.leftContent, 0, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成主要技术方案',
                    operator:  ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              }else if (items == 20) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.leftContent, 1, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成非必要技术特征',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                  this.$set(this.leftContent, 1,  {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成非必要技术特征',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              }else if (items == 30) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.leftContent, 2, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成不清楚之处',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                  this.$set(this.leftContent, 2, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成不清楚之处',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                }
              }else if (items == 40) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.leftContent, 3, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成问题清单',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                  this.$set(this.leftContent, 3,  {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成问题清单',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                }
              } else if (items == 50) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.leftContent, 2, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成交底书内容概述',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                  this.$set(this.leftContent, 2, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成交底书内容概述',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                }
              } else if (60 <= items && items < 70 ) {
                if (this.refreshFlag) {
                  this.$set(this.accordionArr, index, {
                    ...this.accordionArr[index],
                    differenceContent: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.messageData, this.messageData.findIndex(value => value.item == 3), {...this.messageData[this.messageData.findIndex(value => value.item == 3)],accordionArr: this.accordionArr});
                  this.$set(this.leftContent, 3, {...this.messageData[this.messageData.findIndex(value => value.item == 3)],accordionArr: this.accordionArr});
                }else {
                  this.$set(this.accordionArr, index, {
                    ...this.accordionArr[index],
                    differenceContent: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.messageData, this.copyOrder, {accordionArr: this.accordionArr,  item: 3,
                    disabled: true,
                    order: this.order,
                    answer: '请帮我生成对比文件分析',
                    operator: ['refresh', 'exchange','zhongzhi']});
                  this.$set(this.leftContent, 3, {accordionArr: this.accordionArr,  item: 3,
                    disabled: true,
                    order: this.order,
                    answer: '请帮我生成对比文件分析',
                    operator: ['refresh', 'exchange','zhongzhi']});
                }
              } else if (items == 70) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.leftContent, 4, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成关键词/检索式',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                  this.$set(this.leftContent, 4, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成关键词/检索式',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                }
              }else if (items == 80) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  this.$set(this.leftContent, 5, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成IPC分类',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                  this.$set(this.leftContent, 5, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成IPC分类',
                    operator: ['refresh', 'exchange','zhongzhi']
                  });
                }
              }else if (items == 100) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成主要技术方案',
                    operator:  ['copy', 'refresh', 'zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 110) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成非必要技术特征',
                    operator: ['copy', 'refresh','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 121) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成权利要求书',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 125) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成摘要',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
                // this.messageEventList(130)
              } else if (items == 130) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成发明名称',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
                // this.messageEventList(140)
              } else if (items == 140) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成技术领域',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
                // this.messageEventList(150)
              } else if (items == 150) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成背景技术',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 160) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成发明内容',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 170) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成具体实施方式',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 180) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: false,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成具体实施方式',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 190) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成具体实施方式',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 200) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: false,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成具体实施方式',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 210) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                  //   }
                  //   return itemss
                  // })
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成具体实施方式',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 220) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成具体实施方式',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 215) {
                if (this.refreshFlag) {
                  // this.messageData.map(itemss => {
                  //   if (itemss.item == items) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });
                } else {
                  this.$set(this.messageData, this.order, {
                    show: this.type == 3 ? true : false,
                    disabled: false,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: '请帮我生成具体实施方式',
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              } else if (items == 0) {
                if (this.refreshFlag) {
                  this.$set(this.messageData, index, {
                    ...this.messageData[index],
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", "")
                  });

                }else {
                  this.$set(this.messageData, this.order, {
                    show: true ,
                    prompt: this.prompt,
                    disabled: true,
                    liked: null,
                    content: JSON.parse(item).content.replaceAll("#", "").replaceAll("*", ""),
                    messageId: JSON.parse(item).id,
                    parentMessageId: JSON.parse(item).parentMessageId,
                    order: this.order,
                    item: items,
                    answer: this.prompt,
                    operator: items == 0 ? ['copy', 'refresh','zhongzhi'] : ['refresh', 'exchange','zhongzhi']
                  });
                  this.scrollToBottom()
                }
              }
            },
          }).then(({data}) => {

            if(data.code == 1) {
              this.$message.error(data.msg);
              return
            }
            this.flagw = false
            this.flagws = false
            this.recordSteps = {}
            if (items == 0) {
              if (!this.refreshFlag) {
                this.order++;
              }
            }else if (this.type == 2 && items == this.accordionArr[this.accordionArr.length - 1]?.difference) {
              // if (!this.refreshFlag) {
              //   this.order++;
              // }
              this.comparativeFlag++;
            }else if (this.type == 2 && items == this.accordionArr[0]?.difference) {
              if (!this.refreshFlag) {
                this.order++;
              }
              this.comparativeFlag++;
              this.accordionArr.map((item,index) => {
                if(index != 0) {
                  this.messageEventList(item.difference, index)
                }
              })
            }else if (this.type == 1 && items == 10) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(20)
              }
            }else if (this.type == 1 && items == 20) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(30)
              }
            }else if (this.type == 1 && items == 30) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(40)
              }
            }else if (this.type == 2 && items == 10) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(20)
              }
            }else if (this.type == 2 && items == 20) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(50)
              }
            }else if (this.type == 2 && items == 70) {
              if (!this.refreshFlag) {
                this.order++;
              }
            }else if (this.type == 2 && items == 80) {
              if (!this.refreshFlag) {
                this.order++;
              }
            }else if (items == 100) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(110)
              }
            } else if (items == 110) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 121) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 125) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 130) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 140) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 150) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 160) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 170) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(180)
              }
            } else if (items == 180) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 190) {
              if (!this.refreshFlag) {
                this.order++;
                if(this.fufileList.length > 0) {
                  this.messageEventList(200)
                }
              }
            } else if (items == 200) {
              if (!this.refreshFlag) {
                this.order++;
              }
            } else if (items == 210) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(220)
              }
            } else if (items == 220) {
              if (!this.refreshFlag) {
                this.order++;
                this.messageEventList(215)
              }
            }else if (items == 215) {
              if (!this.refreshFlag) {
                this.order++;
              }
            }
          }).catch( error  => {
            console.dir(error.message,"error---")
            if (error.message.indexOf("(reading 'responseURL')") != -1) {
              this.flagw = false
              // this.$message.error('服务器繁忙，请稍后重试');
              store.commit("loadingBool", false);
              return
            } else if (error.code === 'ECONNABORTED') {
              console.log("请求超时");
            } else {
              console.log("请求失败:", error);
            }

            this.flagw = false
            this.$message.error('服务器繁忙，请稍后重试');
            store.commit("loadingBool", false);
          });
        }
      },
      messageEventList(item,index) {
        this.$axios.post(`/aigc/app/new/message`, {
          "chatNumber": this.chatNumber,
          "caseNumber": this.round,
          "chatType": item,
          "prompt": ""
        }).then(({data}) => {
          if (data.code == 0) {
            this.completions(data.data, item,index)
          }
        }).catch(err => {
          this.$message.error('服务器繁忙，请稍后重试');
        })
      },
      messageEvent(arr) {
        //删除10 20 100 110
        let chatTypeList = arr
        let list = []
        chatTypeList.forEach(item => {
          this.messageEventList(item)
        })
      },
      reGenerate(){
        if(this.recordSteps.items) {
          this.handleStopStep()
          this.completions(this.recordSteps.value, this.recordSteps.items, this.recordSteps.index)
        }else {
          this.handleStopStep()
          const element = document.querySelector('.result_cen');
          element?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
          if (this.fileArr) {
            if (this.type == 1 || this.type == 2 || (this.type == 3 && this.value)) {
              if(this.type == 2 && this.fileList.length == 0) {
                this.$message.error('请上传对比文件');
                return;
              }
              this.refreshFlag = false
              this.rotation = false
              this.round = Math.floor(10000 + Math.random() * 90000)
              this.leftContent = [
                {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },          {
                  content: ''
                },
              ];
              this.$axios.post(`/aigc/app/new`, {
                "caseNumber": this.round,
                "type": this.type,
                "patentField": this.value,
                "discloseFileIds": this.fileArr.fileId,
                "similarFileIds": this.type == 2 ?  this.fileList.map(item => item.fileId).join(',') : undefined
              }).then(({data}) => {
                if (data.code == 0) {
                  this.types = this.type
                  this.chatNumber = data.data.chatNumber
                  //调用4个接口 10 20 100 110
                  if(this.type == 3) {
                    this.messageEvent([10, 20])
                  }else if(this.type == 1) {
                    this.messageEvent([10])
                  }else if(this.type == 2) {
                    this.messageEvent([10])
                  }

                }else {
                  this.$message.error(data.msg);
                }
              }).catch(err => {
                this.$message.error('服务器繁忙，请稍后重试');
              })
            } else {
              this.$message.error('请选择专利领域');
            }
          } else {
            this.$message.error('请上传技术交底书');
          }
        }
      },
      generate() {
        this.rotation = true
        this.copyOrder = ''
        this.activeName = 0
        this.activeNames = 0
        this.comparativeFlag = 0
        this.accordionArr = []

        this.fufileList = [];
        this.shifileList = [];
        this.uploadFiles = false;
        this.refreshFlag = false;
        this.order = 0;
        this.prompt = '';
        if(this.type == 2) {
          this.sequence = 2;
        }else {
          this.sequence = 0;
        }

        this.flag10 = false;
        this.flag20 = false;
        // this.fileArr = '';
        // this.value = '';
        this.textarea = '';
        this.round = null;
        this.messageData = [];
        this.chatNumber = ''
        this.handleStopStep()
        const element = document.querySelector('.result_cen');
        element?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
        if (this.fileArr) {
          if (this.type == 1 || this.type == 2 || (this.type == 3 && this.value)) {
            if(this.type == 2 && this.fileList.length == 0) {
              this.$message.error('请上传对比文件');
              return;
            }
            this.refreshFlag = false
            this.rotation = false
            this.round = Math.floor(10000 + Math.random() * 90000)
            this.leftContent = [
              {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },          {
                content: ''
              },
            ];
            this.$axios.post(`/aigc/app/new`, {
              "caseNumber": this.round,
              "type": this.type,
              "patentField": this.value,
              "discloseFileIds": this.fileArr.fileId,
              "similarFileIds": this.type == 2 ?  this.fileList.map(item => item.fileId).join(',') : undefined
            }).then(({data}) => {
              if (data.code == 0) {
                this.types = this.type
                this.chatNumber = data.data.chatNumber
                //调用4个接口 10 20 100 110
                if(this.type == 3) {
                  this.messageEvent([10, 20])
                }else if(this.type == 1) {
                  this.messageEvent([10])
                }else if(this.type == 2) {
                  this.messageEvent([10])
                }

              }else {
                this.$message.error(data.msg);
              }
            }).catch(err => {
              this.$message.error('服务器繁忙，请稍后重试');
            })
          } else {
            this.$message.error('请选择专利领域');
          }
        } else {
          this.$message.error('请上传技术交底书');
        }
      },
      loadId(item) {
        this.$axios.get(`/aigc/app/new/content/download/` + item.id, {
          responseType: "arraybuffer"
        }).then(({code, data, msg}) => {
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = item.title + '.docx'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      fenge(text) {
        const content = text;

        const splitPointRegex = /(.*检索式.*)/; // 匹配包含“关键词”和“检索式”的句子
        const match = content.match(splitPointRegex);

        if (match) {
          const splitPoint = match[0]; // 找到的匹配句子
          const [firstPart, secondPart] = content.split(splitPoint);
          return {
            up: firstPart.trim(),
            down: splitPoint.trim() + "\n" + secondPart.trim()
          }
        } else {
          console.log("没有找到匹配的句子。");
        }
      },
      removeExtension(filename) {
        return filename.substring(0, filename.lastIndexOf('.')) || filename;
      },
      fenglei(text){

        const regex = /(?<=\d+\..*|\-\s)[A-Z]+\d+[\w/]+\s?[\w/]+/g;

// 提取所有匹配的分类号
        const matches = text.match(regex);

// 用中文顿号连接并输出结果
        const result = matches ? matches.join('、') : '';
        return result
      },
      keyEvent(text){
        const regex = /(\S+ - [^、\n]+|(\S+（[^）]+）))/g;
        const matches = [...text.matchAll(regex)];
        const output = matches.map(match => match[0]).join(' 、');
        return output
      },
      jiansuo(text){
// 匹配检索式的正则表达式
        const regex = /[a-zA-Z]+=\([^=]+\)/g;

// 提取所有匹配的检索式
        const matches = text.match(regex);

// 用中文顿号连接并输出结果
        const result = matches ? matches.join('、') : '';
        return result
      },
      listItem(item) {
        console.log(item,"itemitem")
        return this.$axios.put(`/aigc/app/new/message`, {
          content: item.content,
          messageId: item.messageId,
          parentMessageId: item.parentMessageId
        })
      },
      load(item) {
        if(this.flagw) {
          return;
        }
        let list = []
        this.leftContent.map(item => {
          if(item.content) {
            list.push(this.listItem(item))
          }
        })

        Promise.all(list).then(()=>{

          let obj = {}
          if(this.type == 1) {
            obj = {
              //问题清单
              questionList: this.leftContent[3].content,
            }
          }else if(this.type == 2) {
            obj = {
              //利要点分析
              overview: this.leftContent[2].content,
              //专利报告建议关键词
              suggestKeyword: this.keyEvent(this.fenge(this.leftContent[4].content).up),
              //专利报告建议检索式
              suggestTiabc: this.jiansuo(this.fenge(this.leftContent[4].content).down),
              //建议的IPC分类
              suggestIpc: this.fenglei(this.leftContent[5].content),
              //检索报告分析内容   difference 回话类型：  differenceContent 用户确认好的分析内容：
              reportContentList:  this.leftContent[3].accordionArr.map(item => {
                return {
                  difference: item.difference,
                  differenceContent: item.differenceContent
                }
              })

            }
          }else if(this.type == 3) {
            obj = {
              //说明书摘要
              descriptionAbstract: this.leftContent[0].content,
              //权利要求书
              claims: this.leftContent[1].content,
              //说明书
              // description: this.leftContent[0],
              //发明名称
              descriptionName: this.leftContent[2].content,
              //技术领域
              descriptionTech: this.leftContent[3].content,
              //背景技术
              descriptionBackground: this.leftContent[4].content,
              //发明内容
              descriptionContent: this.leftContent[5].content,
              //具体实施方式
              descriptionSpecific: this.leftContent[6].content + '\n\n' + this.leftContent[7].content,
            }
          }
          this.$axios.post(`/aigc/app/new/content/download`, {
            "title": this.fileArr.fileName,
            "caseNumber": this.round,
            "type": this.type,
            "patentField": this.value,
            "chatNumber": this.chatNumber,
            "contentData": JSON.stringify(obj)
          },{
            responseType: "arraybuffer"
          }).then(({code, data, msg}) => {
            const content = data
            const blob = new Blob([content], {
              type: "application/vnd.ms-excel;charset=utf-8"
            });
            const fileName = item.title + '.docx'
            if ('download' in document.createElement('a')) { // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else { // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
            this.history()
          })
        })
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      uploadFns(file, fileList) {
        if (file.code == 0) {
          file.data.name = file.data.fileName
          this.fileList.push(file.data)
          this.$message({
            message: '文件上传成功',
            type: 'success'
          });
        }
      },
      uploadFn(file, fileList) {
        if (file.code == 0) {
          this.fileArr = file.data
          this.$message({
            message: '文件上传成功',
            type: 'success'
          });
        }
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
    }
  }
</script>

<style lang="less">
  .dialogviewFileVisible {
    .v-note-wrapper {
      textarea {
        text-align: justify!important;
        width: 95% !important;
      }
    }
  }
  .oa_drawer {
    top: 120px;
    height: calc(100% - 140px);  /* 50%视口高度加100像素 */
    // 滚动条的宽度
    &::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    &::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    .el-drawer__header {
      margin-bottom: 10px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #555555;
        img {
          margin-right: 8px;
          display: block;
        }
      }
      padding-bottom: 20px;
      box-sizing: border-box;
      border-bottom: 2px solid #C3CFEF;
    }
    .el-drawer.rtl {
      padding: 20px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08), -5px 0px 7px 0px rgba(0,0,0,0.08);
      border-radius: 15px 15px 15px 15px;
      .pagination {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .comparativeFilesDialog,
  .uploadFiles {
    .upload-demo {
      text-align: center;
    }
  }
  .enter_hover {
    padding: 0 !important;
  }
  .oaApplication {
    textarea {
      padding: 0;
      margin: 0;
      line-height: normal; /* 确保行高不会导致额外的空白 */
    }
    /* 修改编辑区域和预览区域的底部空白 */
    .markdown-editor .editor-content,
    .markdown-editor .preview {
      padding-bottom: 0;  /* 去掉不必要的 padding */
      margin-bottom: 0;   /* 去掉不必要的 margin */
    }

    /* 调整整个编辑器的布局以减少空白 */
    .markdown-editor {
      height: auto;  /* 根据内容自动调整高度 */
    }
    .markdown-editor .editor-wrapper,
    .markdown-editor .editor-preview-wrapper {
      padding-bottom: 0;  /* 删除底部的 padding */
      margin-bottom: 0;   /* 删除底部的 margin */
    }

    //手风琴
    .oa_collapse {
      color: #333639;
      border: none;
      .el-collapse-item {
        border: 1px solid #e5e7eb;
        margin: 18px 0;
        padding: 10px 20px;
        box-sizing: border-box;
        background-color: #fff;
        &:hover {
          box-shadow: #ddd 0 0 10px;

        }
        .el-collapse-item__header {
          height: 115px;
          &.is-active {
            height: 73px;
          }
          display: block;
          border: none;
          .el-collapse-item__arrow {
            display: none;
          }
          .titleHeader {
            display: flex;
            width: 100%;
            align-items: center;
            line-height: 1;
            &:nth-of-type(1) {
              height: 40px;
            }
            height: 20px;
            &.active {
              justify-content: space-between;
            }
            .title {
              line-height: 1;
              font-size: 14px;
              font-weight: 700;
            }
            li {
              color: #333639;
              margin-right: 40px;
              font-size: 14px;
              white-space: nowrap;         /* 禁止换行 */
              overflow: hidden;            /* 隐藏溢出部分 */
              text-overflow: ellipsis;     /* 使用省略号 */
              span {
                font-weight: bold;
                font-size: 14px;
                margin-right: 15px;
              }
            }
          }
        }
        .el-collapse-item__wrap {
          border: none;
          .el-collapse-item__content {
            color: #333639;
            font-size: 14px;
            padding-bottom: 15px;
            span {
              font-weight: bold;
              font-size: 14px;
              margin-right: 15px;
            }
          }
        }
      }
    }

  }
</style>
<style lang="less" scoped>
  .enter_hover {
    ul {
      padding: 18px 0 13px 0;
      box-sizing: border-box;
      width: 143px;
      /*height: 240px;*/
      background: rgba(255, 255, 255, 0.58);
      border-radius: 12px 12px 12px 12px;
      border: 2px solid;
      border-image: linear-gradient(123deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)) 2 2;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 22px 0 14px;
        box-sizing: border-box;
        text-align: right;
        font-weight: 500;
        font-size: 14px;
        color: #4E6B97;
        width: 100%;
        height: 28px;
        cursor: not-allowed;
        &.disabled {
          cursor: pointer;
        }

        &:hover {
          background: #C5C1EC;
          border-radius: 0px 0px 0px 0px;
          color: #345B93;
        }
      }
    }
  }

  .oaApplication {
    overflow: hidden;
    .home_top {
      height: 90px;
    }

    .mavonEditor_1 {
      background: #F1F4FC;
      /deep/.v-note-panel {
        padding: 15px;
      }
      /deep/textarea {
        background: #F1F4FC;
      }
      /deep/.content-input-wrapper {
        padding: 0!important;
      }
    }

    .historyList {
      padding: 10px 15px 0 25px;
      box-sizing: border-box;
      width: 320px;
      height: calc(100vh - 93px);
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(7, 13, 67, 0.18);
      border-radius: 8px 8px 8px 8px;
      overflow: hidden;
      /*overflow-y: auto;*/

      .tabs {
        /deep/ .el-tab-pane {
          height: calc(100vh - 280px);
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 10px; // 横向滚动条
            height: 10px; // 纵向滚动条 必写
          }

          // 滚动条的滑块
          &::-webkit-scrollbar-thumb {
            background: #485687;
            border-radius: 10px
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }
        }
      }

      /*滚动条样式*/
      /*&::-webkit-scrollbar {*/
      /*  width: 4px;*/
      /*}*/
      /*&::-webkit-scrollbar-thumb {*/
      /*  border-radius: 10px;*/
      /*  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
      /*  background: rgba(0,0,0,0.2);*/
      /*}*/
      /*&::-webkit-scrollbar-track {*/
      /*  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
      /*  border-radius: 0;*/
      /*  background: rgba(0,0,0,0.1);*/

      /*}*/

      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #555555;
        padding: 0.5rem 0.7rem;
        box-sizing: border-box;

        img {
          width: 41px;
          height: auto;
          margin-right: 14px;
        }
      }

      .line {
        width: 260px;
        height: 2px;
        margin-top: 10px;
        margin-bottom: 33px;
        border: 1px solid #DBE4F5;
      }

      .item {
        line-height: 1;
        margin-bottom: 21px;

        p:nth-of-type(1) {
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          cursor: pointer;
          line-height: 14px;
          margin-bottom: 9px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p:nth-of-type(2) {
          font-weight: 400;
          font-size: 12px;
          color: #666666;
        }
      }
    }

    .oaApplication_cen {
      padding: 30px 30px 0 30px;
      box-sizing: border-box;

      .oaApplication_o {
        position: relative;

        .history {
          cursor: pointer;
          right: 0;
          top: 61px;
          width: 73px;
          height: auto;
          position: absolute;
        }

        padding-top: 36px;
        box-sizing: border-box;
        background: url("../../assets/images/oaApplication/bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;

        .oaApplication_content {
          position: relative;
          .tabSwitch {
            z-index: 2;
            position: absolute;
            width: calc(100% - 200px);;
            max-width: 1500px;
            display: flex;
            top: 23px;
            left: 50px;
            li {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 4px;
              width: 110px;
              height: 35px;
              background: #E0E4EB;
              font-weight: bold;
              font-size: 14px;
              color: #666666;
              border-radius: 10px 10px 0 0;
              &.active {
                color: #172A6B;
                background: #B0C2F2;
              }
            }
          }
          padding: 60px 30px 24px 30px;
          box-sizing: border-box;
          width: calc(100% - 200px);;
          max-width: 1500px;
          height: calc(100vh - 170px); /* 高度为视口高度减去 100px */
          background: rgba(255,255,255,0.4);
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);
          border-radius: 12px 12px 12px 12px;
          border: 2px solid #ffffff;
          display: flex;
          overflow: hidden;
          /*flex-direction: column;*/
          justify-content: space-between;
          .result {
            // 滚动条的宽度
            &::-webkit-scrollbar {
              width: 10px; // 横向滚动条
              height: 10px; // 纵向滚动条 必写
            }
            // 滚动条的滑块
            &::-webkit-scrollbar-thumb {
              background: hsla(0, 0%, 53%, .1);
              border-radius: 10px
            }
            &::-webkit-scrollbar-track {
              background: transparent;
            }
            .scroll_bar /deep/ .el-scrollbar__wrap {
              overflow-x: hidden !important;
            }
            padding: 20px;
            box-sizing: border-box;
            width: calc(100% - 690px);
            height: calc(100vh - 245px); /* 高度为视口高度减去 100px */
            overflow-y: auto;
            background: #FFFFFF;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
            border-radius: 12px 12px 12px 12px;
            position: relative;
            .titles {
              margin: 15px 0;
              font-size: 12px;
            }
            .load {
              cursor: pointer;
              position: absolute;
              right: 25px;
              top: 0px;
              width: 31px;
              height: auto;
              z-index: 999;
            }
            .result_cen {
              // 滚动条的宽度
              &::-webkit-scrollbar {
                width: 10px; // 横向滚动条
                height: 10px; // 纵向滚动条 必写
              }
              // 滚动条的滑块
              &::-webkit-scrollbar-thumb {
                background: hsla(0, 0%, 53%, .1);
                border-radius: 10px
              }
              &::-webkit-scrollbar-track {
                background: transparent;
              }

              height: calc(100vh - 340px); /* 高度为视口高度减去 100px */
              overflow-y: auto;
            }
            .btn {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 120px;
              height: 38px;
              background: linear-gradient(252deg, #2E72D0 0%, #1B427A 100%);
              border-radius: 8px 8px 8px 8px;
              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
              &:nth-of-type(1) {
                background: #e2e9fe;
                border: 1px solid #4178c2;
                color: #555555;
              }
            }
          }
          .chat {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 636px;
            height: calc(100vh - 240px); /* 高度为视口高度减去 100px */
            /*background: #FFFFFF;*/
            /*box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);*/
            /*border-radius: 12px 12px 12px 12px;*/

            .upload {
              .rotation {
                width: 100%;
                display: flex;
                justify-content: center;
                height: 20px;
                cursor: pointer;
              }
              .upload_item_1 {
                width: 100%;
                height: 75px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
              .upload_item_2 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
              }
              padding: 0 35px 20px 33px;
              box-sizing: border-box;
              width: 100%;
              height: auto;
              background: rgba(255, 255, 255, 0.45);
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
              border-radius: 12px 12px 12px 12px;
              /*border: 2px solid;*/
              border-image: linear-gradient(123deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)) 2 2;
              overflow: hidden;

              .uploadFile {
                width: 233px;
                height: 40px;
                background: #E2E9FE;
                border-radius: 8px 8px 8px 8px;
                border: 2px dashed #4178C2;
                padding-left: 42px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: 500;
                font-size: 12px;
                color: #555555;

                i {
                  margin-right: 5px;
                }
              }

              .uploadFiled {
                padding-left: 12px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 316px;
                height: 38px;
                background: #D1DBF3;
                border-radius: 5px 5px 5px 5px;
                font-weight: 400;
                font-size: 14px;
                color: #2E4172;

                p {
                  text-align: left;
                  white-space: nowrap; /* 不换行 */
                  overflow: hidden; /* 隐藏溢出的内容 */
                  text-overflow: ellipsis; /* 显示省略号 */
                  width: 260px; /* 设定容器宽度 */
                }

                i:nth-of-type(1) {
                  font-size: 16px;
                  margin-right: 4px;
                }

                i:nth-of-type(2) {
                  font-size: 18px;
                  margin-right: 2px;
                }
              }

              .select {
                /deep/ input {
                  width: 103px;
                  height: 38px;
                  background: #E5ECFF;
                  border-radius: 8px 8px 8px 8px;
                  border: 1px solid #6C80B3;
                  font-weight: 500;
                  font-size: 14px;
                  color: #172A6B;
                }
              }

              .btn {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 38px;
                background: linear-gradient(252deg, #2E72D0 0%, #1B427A 100%);
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
              }
            }

            .chat_content {
              padding: 13px;
              box-sizing: border-box;
              height: calc(100% - 120px); /* 高度为视口高度减去 100px */

              .chatBox {
                padding: 30px 10px;
                box-sizing: border-box;
                //result-ico
                // 滚动条的宽度
                &::-webkit-scrollbar {
                  width: 10px; // 横向滚动条
                  height: 10px; // 纵向滚动条 必写
                }

                // 滚动条的滑块
                &::-webkit-scrollbar-thumb {
                  background: #485687;
                  border-radius: 10px
                }

                &::-webkit-scrollbar-track {
                  background: transparent;
                }

                .scroll_bar /deep/ .el-scrollbar__wrap {
                  overflow-x: hidden !important;
                }

                height: calc(100% - 60px); /* 高度为视口高度减去 100px */
                overflow-y: auto;
                .myChatStopGenerate_box {
                  display: flex;
                  justify-content: center;
                  .myChatStopGenerate{
                    font-weight: 500;
                    font-size: 14px;
                    display: inline-block;
                    padding: 8px 15px;
                    border-radius: 8px 8px 8px 8px;
                    span{
                      margin-left: 5px;
                      vertical-align: middle;
                    }
                    svg{
                      vertical-align: middle;
                    }
                  }
                }
                .chatBox_cen {
                  margin-bottom: 30px;

                  .chat_left {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    margin-bottom: 20px;

                    .content.left {
                      padding: 10px;
                      box-sizing: border-box;
                      max-width: 249px;
                      word-break: break-all;
                      background: #A2C1FF;
                      border-radius: 5px 5px 5px 5px;
                      font-weight: 400;
                      font-size: 12px;
                      color: #000000;
                    }

                    img {
                      margin-left: 5px;
                      height: 33px;
                    }
                  }

                  .chat_right {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;

                    .content.right {
                      /deep/ .v-note-edit.divarea-wrapper .content-input-wrapper {
                        padding: 0 !important;
                      }

                      position: relative;
                      padding: 10px;
                      box-sizing: border-box;
                      max-width: 377px;
                      word-break: break-all;
                      background: #FFFFFF;
                      border-radius: 5px 5px 5px 5px;
                      font-weight: 400;
                      font-size: 12px;
                      color: #000000;

                      .collapse {
                        /deep/.title {
                          width: 255px;
                          white-space: nowrap;      /* 禁止换行 */
                          overflow: hidden;         /* 隐藏溢出的文本 */
                          text-overflow: ellipsis;  /* 超出部分显示省略号 */
                        }
                      }

                      .operator {
                        img {
                          width: 14px;
                          height: auto;
                          margin-right: 8px;
                          cursor: pointer;
                        }

                        position: absolute;
                        bottom: -25px;
                      }
                    }

                    img {
                      margin-right: 5px;
                      height: 34px;
                    }
                  }
                }
              }

              .enter {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .generated {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 82px;
                  height: 47px;
                  background: url("../../assets/images/oaApplication/sends.png") no-repeat;
                  background-size: 100% 100%;
                  font-weight: 500;
                  font-size: 14px;
                  color: #FFFFFF;
                  margin-left: 20px;
                }

                .input_ {
                  padding: 0 9px 0 12px;
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 48px;
                  background: #FFFFFF;
                  border-radius: 8px 8px 8px 8px;
                  border: 1px solid #8DA8CE;

                  img {
                    cursor: pointer;
                    width: auto;
                    height: 40px;
                  }

                  /deep/ input {
                    border: none;
                    outline: none;
                  }
                }
              }

              background: url("../../assets/images/oaApplication/miao.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }

    }
  }
</style>
